import { Component, OnInit, HostListener } from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { localStorageVal } from "src/app/_config/config";
import { TenantServices } from "src/app/_services/tenant.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-service-access-table",
  templateUrl: "./service-access-table.component.html",
  styleUrls: ["./service-access-table.component.scss"],
})
export class ServiceAccessTableComponent implements OnInit {
  public showLoader = false;
  public datatableResponsive = false;
  public screenWidth: number;
  public ColumnMode = ColumnMode;
  public reorderable = true;
  public loadingIndicator = true;
  public dataList: any = [];
  public perPage = 10;
  public pageNumber = 1;
  public totalCount = 0;
  public filterObj: any = {};
  public page = 1;
  public queryParams = {
    perPage: this.perPage,
    pageNumber: this.pageNumber,
    sortKey: "",
    sortOrder: "",
  };
  rowData: any;
  public modalRefEdit: any;
  tenantId: string;
  constructor(
    public modal: NgbModal,
    private tenantService: TenantServices,
    public toastrService: ToastrService,
    public translate: TranslateService,
  ) {}
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 991) {
      this.datatableResponsive = true;
    } else {
      this.datatableResponsive = true;
    }
  }
  ngOnInit(): void {
    this.tenantId = localStorage.getItem(localStorageVal.tenantId);

    this.getTenantData();
  }
  public getTenantData() {
    this.showLoader = true;

    this.tenantService.getApplicationTenantsData().subscribe(
      (response) => {
        if (response.code === 200) {
          this.dataList = response.result;
          this.totalCount = response.length;
        }
      },
      (error) => {
        setTimeout(() => {
          if (!error.error.message) {
            this.toastrService.error(this.translate.instant("errors.connectionError"), "");
          } else {
            this.toastrService.error(error.error.message, "");
          }
        }, 1000);
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  public onSearch(text) {
    this.pageReset();
  }
  pageReset() {
    this.pageNumber = 1;
    this.queryParams.pageNumber = this.pageNumber;
  }

  public filter() {}

  public loadPage(page) {
    this.showLoader = true;
    this.queryParams.pageNumber = page;
  }

  editData(dataRow: any, row) {
    this.rowData = row;
    this.open(dataRow);
  }

  open(content) {
    this.modalRefEdit = this.modal.open(content, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "end-side-panel dark-modal-25rem",
    });
  }
}
