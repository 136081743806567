<div class="custom-datatable">
  <ng-container *ngIf="showLoader">
    <ngx-skeleton-loader animation="false" count="10" [theme]="{ width: '100%', height: '40px' }">
    </ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngIf="!showLoader">
    <ngx-datatable
      [rows]="dataList"
      [scrollbarH]="datatableResponsive"
      class="bootstrap"
      [loadingIndicator]="loadingIndicator"
      [headerHeight]="32"
      [footerHeight]="45"
      [rowHeight]="'auto'"
      [columnMode]="ColumnMode.force"
      [reorderable]="reorderable"
    >
      <ngx-datatable-column [width]="180" [resizeable]="false" name="fullName">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>Country</span>
          <div class="search">
            <input
              type="search"
              #fullName
              (input)="onSearch(fullName)"
              name="fullName"
              id="fullName"
              class="form-control search-bar"
              placeholder=" Enter the city"
            />
            <span class="filter" (click)="filter()"><img src="./../assets/images/filter-icon.svg" alt="Moniic" /></span>
          </div>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <span class="city span-column">
            {{ row?.country ? row.country["fullName"] : "---" }}
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="updated-at" [sortable]="false" [width]="230">
        <ng-template
          let-column="column"
          class="partnerNameHeading"
          ngx-datatable-header-template
          let-row="row"
          [width]="230"
        >
          <span> Action </span>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <span class="action-btn call-btn" (click)="editData(dataRow, row)">
            <img src="./../assets/images/edit.svg" alt="edit" />
          </span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div class="inner-content alignment" *ngIf="dataList?.length === 0">
      {{ "errors.noDataFound" | translate }}
    </div>
  </ng-container>
</div>

<ng-template #dataRow let-modal>
  <app-service-access-country-modal [rowData]="rowData" [countriesList]="dataList"></app-service-access-country-modal>
</ng-template>
