<div class="modal-body activate">
    <img class="icon" src="./../assets/images/icon-pop-up-confirmation.svg" alt="logout">
    <h3 class="alertText">Discard Changes?</h3>    
    <span class="alertContent"> <p>Are you sure you want to discard <br> all your changes?</p></span>
    <div style="display: flex;">
      <a class="button-secondry" (click)="editBack()">BACK TO EDITING</a>
      &nbsp;&nbsp;
      <a class="btn-confirm" (click)="hide()">YES, CANCEL</a>
    </div>

  </div>
