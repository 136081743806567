import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";

import { AuthServices } from "../auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "src/app/_config/url-endpoint";

const yachtPartnerUrl = endpoints.yacht.partnerUrl;

@Injectable({
  providedIn: "root",
})
export class YachtPartnerService {
  private savePartnerVal = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthServices) {}

  /**
   * Partners
   *
   * @param {string} queryString
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  getPartners(queryString): Observable<any> {
    let url = `${yachtPartnerUrl}?${queryString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   * Create Partner
   *
   * @param {string} body
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  createPartner(body): Observable<any> {
    return this.http.post(`${yachtPartnerUrl}`, body, this.authService.getAccessHeaders());
  }

  /**
   * Partner Details
   *
   * @param {string} partnerId
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  getPartnerDetails(partnerId): Observable<any> {
    return this.http.get(yachtPartnerUrl + "/" + partnerId, this.authService.getAccessHeaders());
  }

  /**
   * Edit Partner
   *
   * @param {string} partnerId
   * @param {string} body
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  editPartner(partnerId, body): Observable<any> {
    return this.http.patch(yachtPartnerUrl + "/" + partnerId, body, this.authService.getAccessHeaders());
  }

  /**
   * Partner Count
   *
  
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  getPartnerCount(): Observable<any> {
    let tenantUrlString = "";
    let url = `${yachtPartnerUrl}/status-count${tenantUrlString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   * Change Partner Status
   *
   * @param {string} partnerId
   * @param {string} body
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  changePartnerStatus(partnerId, body): Observable<any> {
    return this.http.post(
      endpoints.partnerPage.changePartnerStatus + partnerId,
      body,
      this.authService.getAccessHeaders(),
    );
  }

  /**
   * Partner
   *
  
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  getPartner(): Observable<any> {
    return this.http.get(endpoints.partnerPage.getPartner, this.authService.getAccessHeaders());
  }

  /**
   * DownloadFile
   *
   * @param {string} queryString
   * @returns {Observable<any>}
   * @memberof YachtPartnerService
   */

  downloadFile(queryString): Observable<any> {
    const url = `${yachtPartnerUrl}/export?${queryString}`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(url, {
      headers: (this.authService.getAccessHeaders() as { headers: HttpHeaders }).headers,
      responseType: "blob",
    });
  }

  public setRefreshPartner(data: any) {
    this.savePartnerVal.next({ result: data });
  }

  public getRefreshPartner(): Observable<any> {
    return this.savePartnerVal.asObservable();
  }
}
