import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  activeTabNav = "serviceAccessCity";

  constructor() {}

  ngOnInit(): void {}
  public changeNav(activeTabVal: string) {
    this.activeTabNav = activeTabVal;
  }
}
