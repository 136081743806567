<!-- multi-select.component.html -->
<div class="multi-select-container">
  <button
    type="button"
    class="multi-select-field"
    (click)="toggleDropdown()"
    [id]="id"
  >
    <!-- Placeholder or selected values -->
    <span *ngIf="selectedOptions.size === 0">Select options</span>
    <span *ngIf="selectedOptions.size > 0"
      >{{ selectedOptions.size }} selected</span
    >
  </button>

  <div *ngIf="isDropdownOpen" class="multi-select">
    <ul>
      <li *ngFor="let option of options">
        <button
          type="button"
          aria-label="Select option"
          (click)="toggleOption(option)"
        >
          <input tabindex="-1" type="checkbox" [checked]="isSelected(option)" />
          {{ option.fieldValue }}
        </button>
      </li>
    </ul>
  </div>
</div>
