import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import * as countrycitystatejson from "countrycitystatejson";
import { endpoints } from "../_config/url-endpoint";

@Injectable({
  providedIn: "root",
})
export class CountryServices {
  private countryData = countrycitystatejson;
  private applicationCountryData = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}

  getCountries() {
    return this.countryData.getCountries();
  }

  getStatesByCountry(countryShotName: string) {
    return this.countryData.getStatesByShort(countryShotName);
  }

  getCitiesByState(country: string, state: string) {
    return this.countryData.getCities(country, state);
  }

  public setApplicationCountry(data: any) {
    this.applicationCountryData.next({ result: data });
  }

  public getApplicationCountry(): Observable<any> {
    return this.applicationCountryData.asObservable();
  }

  getApplicationCountriesData(): Observable<any> {
    const TRUE = true;
    let url = `${endpoints.configurations.getCountryUrl}?isActive=${TRUE}`;
    return this.http.get(url);
  }
}
