import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { AuthServices } from "./auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "../_config/url-endpoint";
import { OfferingType } from "../_config/config";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(private http: HttpClient, private authService: AuthServices) {}

  /**
   * Offerings
   *
   * @returns {Observable<any>}
   * @memberof SettingsService
   */

  getOfferings(type: OfferingType): Observable<any> {
    const url = `${endpoints.configurations.getOfferingsListUrl}?type=${type}`;
    return this.http.get(url);
  }

  /**
   * Offerings Tenant
   *
   * @param {string} tenantId
   * @returns {Observable<any>}
   * @memberof SettingsService
   */

  getOfferingsTenant(tenantId: string): Observable<any> {
    let url = `${endpoints.configurations.getOfferingsSearchUrl}/${tenantId}/offerings`;
    return this.http.get(url);
  }

  /**
   * Update Offerings
   *
   * @param {string} body
   * @returns {Observable<any>}
   * @memberof SettingsService
   */

  updateOfferings(body): Observable<any> {
    return this.http.post(endpoints.configurations.getOfferingsUrl, body);
  }

  getOfferingsCountry(countryTenantId: string): Observable<any> {
    let url = `${endpoints.configurations.getOfferingsCountrySearchUrl}/${countryTenantId}/offerings`;
    return this.http.get(url);
  }

  updateOfferingsCountry(body): Observable<any> {
    return this.http.post(endpoints.configurations.getOfferingsCountryUrl, body);
  }
}
