import { RouterModule, Routes } from "@angular/router";

import { AuthGuardGuard } from "../util/authGuard/auth-guard.guard";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "customers",
    loadChildren: () => import("./customers/customers.module").then((m) => m.CustomersModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "subscriptions",
    loadChildren: () => import("./subscriptions/subscriptions.module").then((m) => m.SubscriptionsModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "car",
    loadChildren: () => import("./assets/car/car.module").then((m) => m.CarModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "yacht",
    loadChildren: () => import("./assets/yacht/yacht.module").then((m) => m.YachtModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "bookings/car",
    loadChildren: () => import("./all-bookings/car-bookings/car-bookings.module").then((m) => m.CarBookingsModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "bookings/yacht",
    loadChildren: () =>
      import("./all-bookings/yacht-bookings/yacht-bookings.module").then((m) => m.YachtBookingsModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "assistant/tasks",
    loadChildren: () =>
      import("./all-bookings/pa-tasks/personal-assistants-tasks.module").then((m) => m.PersonalAssistantsTasksModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "configurations",
    loadChildren: () => import("./account-settings/account-settings.module").then((m) => m.AccountSettingsModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "driver",
    loadChildren: () =>
      import("./assets/driver-management/driver-management.module").then((m) => m.DriverManagementModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "analytics",
    loadChildren: () => import("./analytics/analytics.module").then((m) => m.AnalyticsModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "notification",
    loadChildren: () => import("./notification/notification.module").then((m) => m.NotificationModule),
    canActivate: [AuthGuardGuard],
  },

  {
    path: "partners/cars",
    loadChildren: () => import("./partners/partners-cars/partners.module").then((m) => m.PartnersModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "partners/yachts",
    loadChildren: () => import("./partners/partners-yachts/partners.module").then((m) => m.PartnersModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "reports/cars",
    loadChildren: () => import("./reports/cars/reports.module").then((m) => m.ReportsModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "reports/yachts",
    loadChildren: () => import("./reports/yachts/reports.module").then((m) => m.ReportsModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "chauffeurMap",
    loadChildren: () =>
      import("./active-chauffeur-map/active-chauffeur-map.module").then((m) => m.ActiveChauffeurMapModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "kpi",
    loadChildren: () => import("./kpi/kpi.module").then((m) => m.KpiModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "feed",
    loadChildren: () => import("./live-feed/live-feed.module").then((m) => m.LiveFeedModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "settings",
    loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule),
    canActivate: [AuthGuardGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
