import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { Injectable } from "@angular/core";
import { endpoints } from "../_config/url-endpoint";

/**
 *
 *
 * @export
 * @class TenantServices
 */
@Injectable({
  providedIn: "root",
})
export class TenantServices {
  private tenantData = new Subject<any>();

  /**
   *
   * Creates an instance of TenantServices.
   *
   * @param {HttpClient} http
   * @memberof TenantServices
   */
  constructor(private http: HttpClient) {}

  /**
   *
   *
   * @returns {Observable<any>}
   * @memberof TenantServices
   */
  getTenantData(): Observable<any> {
    const TRUE = true;
    let url = `${endpoints.tenant.getTenantUrl}?isActive=${TRUE}`;
    return this.http.get(url);
  }

  /**
   *
   *
   * @param {*} data
   * @memberof TenantServices
   */
  public setTenant(data: any) {
    this.tenantData.next({ result: data });
  }

  /**
   *
   *
   * @returns {Observable<any>}
   * @memberof TenantServices
   */
  public getTenant(): Observable<any> {
    return this.tenantData.asObservable();
  }

  getApplicationTenantsData(): Observable<any> {
    const TRUE = true;
    let url = `${endpoints.configurations.getTenantUrl}?isActive=${TRUE}`;
    return this.http.get(url);
  }
}
