import { Component, OnInit, Input } from "@angular/core";
import { localStorageVal, OfferingType } from "src/app/_config/config";
import { DiscardChangesComponent } from "src/app/dashboard/shared-components/discard-changes/discard-changes.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TenantServices } from "src/app/_services/tenant.service";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { SettingsService } from "../../../../_services/settings.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-service-access-modal",
  templateUrl: "./service-access-modal.component.html",
  styleUrls: ["./service-access-modal.component.scss"],
})
export class ServiceAccessModalComponent implements OnInit {
  loading;
  @Input() rowData: any;
  @Input() tenantsList: any;

  tenants: any[] = [];
  public serviceAccessForm: FormGroup;
  isEditMode: boolean;
  offeringsData: any[] = [];
  offeringsList: any[] = [];
  constructor(
    private modal: NgbModal,
    private tenantService: TenantServices,
    private formBuilder: FormBuilder,
    private settingsServices: SettingsService,
    public toastrService: ToastrService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.getOfferingsTenant();

    this.populateTenants();
    this.initializeForm();
    this.getOfferings();
    this.populateFormForEdit(this.rowData);
  }

  populateTenants() {
    this.tenants = this.tenantsList.map((tenant: any) => ({
      id: tenant.tenantId,
      fullName: tenant.tenant.fullName,
    }));
  }

  public getOfferingsTenant() {
    this.settingsServices.getOfferingsTenant(this.rowData.tenantId).subscribe(
      (res) => {
        if (res.code === 200) {
          this.offeringsData = res.result;
          this.initializeForm();
        }
      },
      (error) => {
        // set loading to false
        this.loading = false;

        // show error message
        setTimeout(() => {
          if (!error.error.message) {
            this.toastrService.error(this.translate.instant("errors.connectionError"), "");
          } else {
            this.toastrService.error(error.error.message, "");
          }
        }, 1000);
      },
    );
  }
  public getOfferings() {
    this.settingsServices.getOfferings(OfferingType.CITY).subscribe(
      (res) => {
        if (res.code === 200) {
          this.offeringsList = res.result;
          this.initializeForm();
        }
      },
      (error) => {
        // set loading to false
        this.loading = false;

        // show error message
        setTimeout(() => {
          if (!error.error.message) {
            this.toastrService.error(this.translate.instant("errors.connectionError"), "");
          } else {
            this.toastrService.error(error.error.message, "");
          }
        }, 1000);
      },
    );
  }

  initializeForm() {
    const controls = {};
    if (Array.isArray(this.offeringsList)) {
      // Dynamically create form controls for each offering
      this.offeringsList.forEach((offering) => {
        if (offering && typeof offering.name === "string") {
          controls[offering.name.toLowerCase()] = [false, Validators.required];
        } else {
          console.error("Invalid offering or name:", offering);
        }
      });
    } else {
      // console.error('offeringsList is not an array or is undefined:', this.offeringsList);
    }
    this.serviceAccessForm = this.formBuilder.group({
      tenantId: ["", Validators.required],
      ...controls, // Spread dynamically created controls
    });
    this.populateFormForEdit(this.rowData);
    if (this.rowData) {
      this.populateFormForEdit(this.rowData);
    }
  }

  populateFormForEdit(rowData: any) {
    if (this.tenants) {
      const matchingTenant = this.tenants.find((tenant) => tenant.fullName === rowData.tenant.fullName);
      if (matchingTenant) {
        this.serviceAccessForm.patchValue({
          tenantId: matchingTenant.id,
        });
      }
    }

    if (this.offeringsList) {
      const offeringsControls = {};
      this.offeringsList.forEach((service) => {
        const isServiceSelected = this.offeringsData.includes(service.name); // Check if the service is in offeringsData
        offeringsControls[service.name.toLowerCase()] = isServiceSelected;
      });

      // Patch the form with offerings
      this.serviceAccessForm.patchValue({
        ...offeringsControls, // Spread the controls for the form
      });
    }
  }

  serviceAccessSubmit(value) {
    const offering = {};
    Object.keys(value).forEach((key) => {
      if (key !== "tenantId") {
        offering[key.toUpperCase()] = value[key] || false;
      }
    });

    // Create the payload
    const payload = {
      tenantId: value.tenantId,
      offering,
    };

    this.settingsServices.updateOfferings(payload).subscribe(
      (response) => {
        if (response.code === 201) {
          this.toastrService.success("Offerings updated successfully!", "Success");
        } else if (response.code === 400) {
          this.toastrService.error(response.message || "An error occurred.", "Error");
        } else {
          this.toastrService.warning("Unexpected response received.", "Warning");
        }

        // Handle success actions
        this.modal.dismissAll();
      },
      (error) => {
        this.toastrService.error("An unexpected error occurred.", "Error");
      },
    );
  }

  discardChanges() {
    this.modal.open(DiscardChangesComponent, {
      scrollable: true,
      windowClass: "discard-modal",
    });
  }
}
