<div>
  <!-- input -->
  <div class="input-section">
    <input
      class="form-control"
      type="text"
      name="timeRange"
      formControlName="timeRange"
      placeholder="Pick Time Range"
      readonly
    />
    <button (click)="toggleShowState()">click</button>
  </div>
  <!-- modal -->
  <div class="modal-section" [class.hidden]="!isShow">
    <label for="RangeSlider">Set Charter Time</label>
    <!-- container -->
    <div class="slider-container">
      <div class="start-popup" [class.hidden]="!showStartPopup">{{ rangeStartDisplay }} {{ rangeStartMeridiem }}</div>
      <div class="end-popup" [class.hidden]="!showEndPopup">{{ rangeEndDisplay }} {{ rangeEndMeridiem }}</div>
      <!-- slider -->
      <div id="RangeSlider" class="range-slider">
        <div>
          <div class="range-slider-val-left"></div>
          <div class="range-slider-val-right"></div>
          <div class="range-slider-val-range"></div>

          <!-- hnadler -->
          <div class="range-slider-handle range-slider-handle-left">
            <div class="range-slider-tooltip range-slider-tooltip-left">
              <span class="range-slider-tooltip-text">{{ minDisplayValue }} {{ minMeridiem }}</span>
            </div>
          </div>
          <!-- handler -->
          <div class="range-slider-handle range-slider-handle-right">
            <div class="range-slider-tooltip range-slider-tooltip-right">
              <span>{{ maxDisplayValue }} {{ maxMeridiem }}</span>
            </div>
          </div>
        </div>

        <input
          type="range"
          class="range-slider-input-left"
          tabindex="0"
          [max]="rangeEnd"
          [min]="rangeStart"
          step="1"
          [(ngModel)]="minValue"
          (input)="onLeftInput($event)"
        />
        <input
          type="range"
          class="range-slider-input-right"
          tabindex="0"
          [max]="rangeEnd"
          [min]="rangeStart"
          step="1"
          [(ngModel)]="maxValue"
          (input)="onRightInput($event)"
        />
      </div>
    </div>
    <!-- result -->
    <div class="result">
      <span>{{ result }} Hr</span>
    </div>
    <button class="btn btn-save" (click)="onUpdateTime()">Update Time</button>
  </div>
</div>

<!-- HOW TO USE -->
<!-- 
PLACE THIS COMP SELECTOR IN THE PARENT COMP HTML FILE

<app-range-slider
  [rangeStart]="21"
  [rangeEnd]="38"
  [initMinValue]="27"
  [initMaxValue]="30"
  (valueChange)="onRangeChange($event)"
></app-range-slider> 

PLACE THIS FUNCTION IN THE PARENT COMP LOGIC FILE

onRangeChange(range: { minValue: number; maxValue: number }): void {
  console.log("Range changed:", range);
  // Use the new range values here.
}
-->
