<div class="main-container">
  <div class="input-container">
    <label>{{ label }}</label>
    <span *ngIf="price" class="price">{{ price }} AED</span>
  </div>

  <!-- <div class="counter-container" *ngIf="withCount">
    <span class="operator" [class.pointer]="!readOnly" [class.disabled]="countState <= min" (click)="decrement()">-</span>
    <span class="counter">{{ countState }}</span>
    <span class="operator" [class.pointer]="!readOnly" [class.disabled]="countState >= max" (click)="increment()">+</span>
  </div> -->

  
    <div class="counter-container" *ngIf="withCount">
      <span
        class="operator"
        [class.pointer]="!readOnly"
     
        (click)="decrement()"
      >
        -
      </span>
      <span class="counter">{{ countState }}</span>
      <span
        class="operator"
        [class.pointer]="!readOnly"
        [class.disabled]="countState >= max"
        (click)="increment()"
      >
        +
      </span>
    </div>
  
  
</div>
