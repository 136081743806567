import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CamelCaseToTitlePipe } from "src/app/_pipes/camelCaseToTitle.pipe";
import { CapitalizeFLetterPipe } from "src/app/_pipes/capitalizeFLetter.pipe";
import { CommonModule } from "@angular/common";
import { DatePipe } from "@angular/common";
import { HeadCasePipe } from "../_pipes/headCase.pipe";
import { MinutesToHourConvertPipe } from "src/app/_pipes/minutesToHourConvert.pipe";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgModule } from "@angular/core";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NumericInput } from "../_directives/directive";
import { RatingModule } from "ngx-bootstrap/rating";
import { TimeZoneDatePipe } from "../_pipes/timeZoneDate.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { dateTimeFormatPipe } from "src/app/_pipes/dateFormate.pipe";
import { MultiSelectComponent } from "./shared-components/multi-select/multi-select.component";
import { ComboBoxComponent } from "./shared-components/combo-box/combo-box.component";
import { CounterComponent } from "./shared-components/counter/counter.component";
import { RangeSliderComponent } from "./shared-components/range-slider/range-slider.component";

@NgModule({
  declarations: [
    NumericInput,
    HeadCasePipe,
    CapitalizeFLetterPipe,
    dateTimeFormatPipe,
    MinutesToHourConvertPipe,
    CamelCaseToTitlePipe,
    TimeZoneDatePipe,
    MultiSelectComponent,
    ComboBoxComponent,
    CounterComponent,
    RangeSliderComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbPaginationModule,
    Ng2TelInputModule,
    NgxDropzoneModule,
    RatingModule,
  ],
  exports: [
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbPaginationModule,
    Ng2TelInputModule,
    NgxDropzoneModule,
    RatingModule,
    NumericInput,
    HeadCasePipe,
    CapitalizeFLetterPipe,
    dateTimeFormatPipe,
    MinutesToHourConvertPipe,
    CamelCaseToTitlePipe,
    TimeZoneDatePipe,
    MultiSelectComponent,
    ComboBoxComponent,
    CounterComponent,
    RangeSliderComponent
  ],
  providers: [DatePipe],
})
export class SharedModule {}
