// multi-select.component.ts
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

interface Option {
  id: string | number;
  fieldValue: string | number;
  meta: any;
}

type Options = Option[];

@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"],
})
export class MultiSelectComponent {
  @Input() id: string | undefined;
  @Input() options: Options = [];
  @Input() selectedOptions: Set<string | number> = new Set(); // Input from parent
  @Output() selectedOptionsChange = new EventEmitter<Set<string | number>>(); // Output to notify parent

  isDropdownOpen = false;
  // selectedOptions: Set<string | number> = new Set();

  constructor(private eRef: ElementRef) {}

  // Toggle dropdown open/close
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  // Handle option select
  toggleOption(option: Option) {
    if (this.selectedOptions.has(option.id)) {
      this.selectedOptions.delete(option.id);
    } else {
      this.selectedOptions.add(option.id);
    }
    // Emit the updated selected options
    this.selectedOptionsChange.emit(new Set(this.selectedOptions));
  }

  // Close dropdown on outside click or ESC press
  @HostListener("document:click", ["$event.target"])
  onClickOutside(target: HTMLElement) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  @HostListener("document:keydown.escape")
  onEscapePress() {
    this.isDropdownOpen = false;
  }

  // Check if option is selected
  isSelected(option: Option): boolean {
    return this.selectedOptions.has(option.id);
  }
}
