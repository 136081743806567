<div *ngIf="loading">
  <div class="loader-span"></div>
</div>

<div class="contents">
  <div class="modal-header">
    <h3 class="modal-title">
      <span>Configure Service Access</span>
    </h3>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="serviceAccessForm"
      (ngSubmit)="serviceAccessSubmit(serviceAccessForm.value)"
      id="service-access-form"
    >
      <div class="pb-3">
        <label for="ex1">Country</label>

        <select
          id="tenantId"
          class="form-control"
          formControlName="tenantId"
          name="tenantId"
          placeholder="Select City"
          disabled
        >
          <option class="form-control" value="">Select</option>
          <option *ngFor="let country of countries" [value]="country.id">
            {{ country.fullName }}
          </option>
        </select>
      </div>

      <div class="pb-3 switch" *ngFor="let service of offeringsList">
        <span class="title">{{ service?.displayName }}</span>
        <label class="toggle-switch">
          <input type="checkbox" [formControlName]="service?.name.toLowerCase()" />
          <div class="slider"></div>
        </label>
      </div>
    </form>
  </div>

  <footer class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="discardChanges()">
      {{ "button.cancel" | translate }}
    </button>

    <button
      type="submit"
      class="btn btn-save"
      [disabled]="serviceAccessForm.invalid || !serviceAccessForm.dirty"
      form="service-access-form"
    >
      Save
    </button>
  </footer>
</div>
