import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { AuthServices } from "../auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "src/app/_config/url-endpoint";

@Injectable({
  providedIn: "root",
})
export class YachtService {
  private saveYachtVal = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthServices) {}

   /**
   * Yacht Analytic
   *   
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  getYachtAnalytics(tenantId): Observable<any> {
    let url = `${endpoints.yacht.yachtAnalyticsUrl}?tenantId=${tenantId}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   * Yachts 
   * 
   * @param {string} queryString  
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  getYachts(queryString): Observable<any> {
    let url = `${endpoints.yacht.yachtUrl}?${queryString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   * Miscellaneous 
   * 
   * @param {string} partnerId  
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  // getMiscellaneous(partnerId?: string): Observable<any> {
  //   let url = `${endpoints.yacht.miscellaneous}`;
  //   if (partnerId) {
  //     url = `${endpoints.yacht.miscellaneous}?partnerId=${partnerId}`;
  //   }
  //   return this.http.get(url, this.authService.getAccessHeaders());
  // }
  getMiscellaneous(partnerId?: string, availableDays?: string): Observable<any> {
    let url = `${endpoints.yacht.miscellaneous}`;
    
    const params = new URLSearchParams();
    if (partnerId) {
      params.append("partnerId", partnerId);
    }
    if (availableDays) {
      params.append("availableDays", availableDays);
    }
  
    return this.http.get(`${url}?${params.toString()}`, this.authService.getAccessHeaders());
  }
    /**
   * Facility 
   * 
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  getFacility(): Observable<any> {
    let url = `${endpoints.yacht.facilitiesUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   * Amenities 
   * 
 
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  getAmenities(): Observable<any> {
    let url = `${endpoints.yacht.amenitiesUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

   /**
   * Water Sports 
   * 
   * @param {string} partnerId  
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  // getWaterSports(partnerId?: string): Observable<any> {
  //   let url = `${endpoints.yacht.waterSportsUrl}`;
  //   if (partnerId) {
  //     url = `${endpoints.yacht.waterSportsUrl}?partnerId=${partnerId}`;
  //   }
  //   return this.http.get(url, this.authService.getAccessHeaders());
  // }
  getWaterSports(partnerId?: string, availableDays?: string): Observable<any> {
    let url = `${endpoints.yacht.waterSportsUrl}`;
    
    const params = new URLSearchParams();
    if (partnerId) {
      params.append("partnerId", partnerId);
    }
    if (availableDays) {
      params.append("availableDays", availableDays);
    }
  
    return this.http.get(`${url}?${params.toString()}`, this.authService.getAccessHeaders());
  }
  /**
   * Caterings
   * 
   * @param {string} partnerId  
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  // getCaterings(partnerId?: string): Observable<any> {
  //   let url = `${endpoints.yacht.cateringUrl}`;
  //   if (partnerId) {
  //     url = `${endpoints.yacht.cateringUrl}?partnerId=${partnerId}`;
  //   }
  //   return this.http.get(url, this.authService.getAccessHeaders());
  // }
  getCaterings(partnerId?: string, availableDays?: string): Observable<any> {
    let url = `${endpoints.yacht.cateringUrl}`;
    
    const params = new URLSearchParams();
    if (partnerId) {
      params.append("partnerId", partnerId);
    }
    if (availableDays) {
      params.append("availableDays", availableDays);
    }
  
    return this.http.get(`${url}?${params.toString()}`, this.authService.getAccessHeaders());
  }
 
  /**
   * Routes
   * 
   * @param {string} tenantId  
   * @returns {Observable<any>}
   * @memberof YachtService
   */


  getRoutes(tenantId?: string): Observable<any> {
    let url = `${endpoints.yacht.routesUrl}`;
    if (tenantId) {
      url = `${endpoints.yacht.routesUrl}?tenantId=${tenantId}`;
    }
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  
  /**
   * Yacht Details
   * 
   * @param {string} yachtId  
   * @returns {Observable<any>}
   * @memberof YachtService
   */


  getYachtDetails(yachtId): Observable<any> {
    return this.http.get(endpoints.yacht.yachtUrl + `/${yachtId}`, this.authService.getAccessHeaders());
  }

  /**
   * Create Yacht
   * 
   * @param {string} body  
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  createYacht(body): Observable<any> {
    return this.http.post(`${endpoints.yacht.yachtUrl}`, body, this.authService.getAccessHeaders());
  }

 /**
   * Edit Yacht
   * 
   * @param {string} yachtId 
   * @param {string} body  
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  editYacht(yachtId, body): Observable<any> {
    return this.http.patch(endpoints.yacht.yachtUrl + `/${yachtId}`, body, this.authService.getAccessHeaders());
  }

  /**
   *
   *
   * @param {string} [tenantId]
   * @returns {Observable<any>}
   * @memberof YachtService
   */
  
  getYachtCount(tenantId?: string): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `?tenantId=${tenantId}`;
    }
    let url = `${endpoints.yacht.yachtStatusCountUrl}${tenantUrlString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

 /**
   * Change Yacht Status
   * 
   * @param {string} yachtId 
   * @param {string} body  
   * @returns {Observable<any>}
   * @memberof YachtService
   */

  changeYachtStatus(yachtId, body): Observable<any> {
    return this.http.post(`${endpoints.yacht.yachtActionUrl}/${yachtId}`, body, this.authService.getAccessHeaders());
  }

  /**
   * downloadFile
   * 
   * @param {string} queryParams
   * @returns {Observable<any>}
   * @memberof YachtService
   */


  downloadFile(queryParams): Observable<any> {
    const url = `${endpoints.yacht.yachtDownloadUrl}?${queryParams}`;
    return this.http.get(url, {
      headers: (this.authService.getAccessHeaders() as { headers: HttpHeaders }).headers,
      responseType: "blob",
    });
  }

  // getAddons(addons): Observable<any> {
  //   let url = `${endpoints.yacht.addonsUrl}?id=${addons}`;
  //   return this.http.get(url, this.authService.getAccessHeaders());
  // }
  getAddons(yachtId: string, type: string): Observable<any> {
    const url = `${endpoints.yacht.addonsUrl}?id=${yachtId}&type=${type}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  public setRefreshYacht(data: any) {
    this.saveYachtVal.next({ result: data });
  }

  // done
  public getRefreshYacht(): Observable<any> {
    return this.saveYachtVal.asObservable();
  }
}
