import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "capitalizeFLetter" })
export class CapitalizeFLetterPipe implements PipeTransform {
  public transform(value: string) {
    const dataString = value && value.toLowerCase();
    if (dataString) {
      return dataString.charAt(0).toUpperCase() + dataString.slice(1);
    }
  }
}
