import * as momentTz from "moment-timezone";

import { Pipe, PipeTransform } from "@angular/core";

/**
 * This pipe is used to convert the date to the timezone provided.
 *
 * @export
 * @class timeZoneDatePipe
 * @implements {PipeTransform}
 */
@Pipe({ name: "timeZoneDate" })
export class TimeZoneDatePipe implements PipeTransform {
  /**
   * This method is used to transform the date to the timezone provided.
   *
   * @param {string} value
   * @param {string} [timezone='Asia/Dubai']
   * @param {string} [format='DD MMM, YYYY | hh.mm A']
   * @returns {string}
   * @memberof timeZoneDatePipe
   */
  transform(
    value: string | Date,
    timezone: string = "Asia/Dubai",
    format: string = "DD MMM, yyyy | hh.mm A",
    includeTimeZoneAbbr: boolean = false
  ): string {
    // Check if the value is not provided.
    if (!value) return "";

    // Check if the timezone is not provided.
    if (!timezone) {
      timezone = "Asia/Dubai";
    }

    // Check if the format is not provided.
    if (!format) {
      format = "DD MMM, yyyy | hh.mm A";
    }

    // Convert the date to the provided timezone.
    const date = momentTz(value).tz(timezone);
    let formattedDate = date.format(format);

    // Check if the timezone abbreviation is required.
    if (includeTimeZoneAbbr) {
      const timezoneAbbr = date.zoneAbbr() // Get timezone abbreviation
      formattedDate = `${formattedDate} ${timezoneAbbr}`;
    }

    // Return the formatted date.
    return formattedDate;
  }
}
