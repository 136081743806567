import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'camelCaseToTitle'
  })
  export class CamelCaseToTitlePipe implements PipeTransform {
    transform(value: string): string {
      if (!value) {
        return value;
      }
      const words = value.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  }