import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headCase'
})
export class HeadCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}