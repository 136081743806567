<div class="combo-box-container">
  <button type="button" class="combo-box-field" (click)="toggleDropdown()">
    <span *ngIf="!selectedOption" class="select-placeholder">{{ placeholder }}</span>
    <span *ngIf="selectedOption" class="selected-value">{{ selectedOptionLabel }}</span>
  </button>

  <div *ngIf="isDropdownOpen" class="combo-box-dropdown">
    <input type="text" class="combo-box-search" placeholder="Search..." (input)="onSearchInputChange($event)" />

    <ul>
      <li *ngFor="let option of options">
        <button type="button" (click)="selectOption(option)">
          {{ option.fieldValue }}
        </button>
      </li>
    </ul>
  </div>
</div>
