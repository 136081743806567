<section class="content-wrap">
  <div class="content-main">
    <div class="content-container">
      <div class="header-title">
        <div class="heading-content">
          <h4 class="color-dark tab-analytics">Settings</h4>
        </div>
        <ng-container>
          <div class="middle-content">
            <div class="left-middle middle-240">
              <div class="menu-wrap">
                <ul>
                  <li>
                    <a
                      (click)="changeNav('serviceAccessCity')"
                      [class.active]="activeTabNav == 'serviceAccessCity'"
                      class="active"
                    >
                      <span>Service Access (City)</span>
                    </a>
                  </li>
                  <li>
                    <a
                      (click)="changeNav('serviceAccessCountry')"
                      [class.active]="activeTabNav == 'serviceAccessCountry'"
                      class="active"
                    >
                      <span>Service Access (Country)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="right-content">
              <div class="main-content">
                <ng-container *ngIf="activeTabNav === 'serviceAccessCity'">
                  <app-service-access-table></app-service-access-table>
                </ng-container>
                <ng-container *ngIf="activeTabNav === 'serviceAccessCountry'">
                  <app-service-access-country-table></app-service-access-country-table>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
