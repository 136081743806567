import { Component, Input, Output, EventEmitter, ElementRef, HostListener, OnInit } from "@angular/core";

export interface ComboboxOption {
  id: string | number;
  fieldValue: string | number;
}

// export type ComboboxOptions = ComboboxOption[];

/**
 * ```ts
 * import { Component } from '@angular/core';
 *
 * interface Option {
 *   id: string | number;
 *   fieldValue: string | number;
 * }
 *
 * @Component({
 *   selector: 'app-root',
 *   template: `
 *     <app-combo-box
 *       [id]="'example-combo-box'"
 *       [loadOptions]="fetchOptions"
 *       [(selectedOption)]="selectedOption"
 *     ></app-combo-box>
 *     <p>Selected Option: {{ selectedOption.fieldValue }}</p>
 *   `,
 * })
 * export class AppComponent {
 *   selectedOption: ComboboxOption | null = null;
 *
 *   // If you will access something using `this` then make sure it's an arrow function
 *   fetchOptions = (query: string): Promise<Option[]> => {
 *     // Simulating an async operation (e.g., an API call) to fetch options
 *     const allOptions: Option[] = [
 *       { id: 1, fieldValue: 'Option 1' },
 *       { id: 2, fieldValue: 'Option 2' },
 *       { id: 3, fieldValue: 'Option 3' },
 *     ];
 *
 *     // Filter options based on the search query
 *     const filteredOptions = allOptions.filter((option) =>
 *       option.fieldValue.toString().toLowerCase().includes(query.toLowerCase())
 *     );
 *
 *     return Promise.resolve(filteredOptions);
 *   }
 * }
 * ```
 */
@Component({
  selector: "app-combo-box",
  templateUrl: "./combo-box.component.html",
  styleUrls: ["./combo-box.component.scss"],
})
export class ComboBoxComponent implements OnInit {
  @Input() id: string | undefined;
  @Input() loadOptions: (query: string) => Promise<ComboboxOption[]> = async () => [];
  @Input() selectedOption: ComboboxOption | null = null;
  @Input() placeholder = "Select";
  @Output() selectedOptionChange = new EventEmitter<ComboboxOption | null>();
  isLoading = false;

  searchTerm = "";
  options: ComboboxOption[] = [];
  isDropdownOpen = false;

  constructor(private eRef: ElementRef) {}

  ngOnInit() {
    // Debounce search function to reduce API calls
    this.onSearchInputChange = debounceTime(this.onSearch.bind(this), 500);

    this.onSearch({ target: { value: "" } });
  }

  get selectedOptionLabel(): string | number {
    return this.options?.find((option) => option.id === this.selectedOption.id)?.fieldValue || "None";
  }

  onSearchInputChange(query: string) {}

  onSearch(event: { target: { value: string } }) {
    if (this.isLoading) {
      return;
    }
    const query = event.target.value;
    this.isLoading = true;
    this.loadOptions(query)
      .then((options) => {
        console.log("___ options", options);
        this.options = options;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(option: ComboboxOption) {
    this.selectedOption = option;
    this.selectedOptionChange.emit(this.selectedOption);
    this.isDropdownOpen = false;
  }

  isSelected(option: ComboboxOption): boolean {
    return this.selectedOption.id === option.id;
  }

  @HostListener("document:click", ["$event.target"])
  onClickOutside(target: HTMLElement) {
    if (!this.eRef.nativeElement.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

  @HostListener("document:keydown.escape")
  onEscapePress() {
    this.isDropdownOpen = false;
  }
}

function debounceTime(callback: Function, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}
