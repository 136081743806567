import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateTimeFormat' })
export class dateTimeFormatPipe extends 
    DatePipe implements PipeTransform {
     transform(value: any, args?: any): any {
       return super.transform(value, "HH:mm:ss | dd/MM/yyyy");
     }
   }
