import { number } from "@amcharts/amcharts4/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-range-slider",
  templateUrl: "./range-slider.component.html",
  styleUrls: ["./range-slider.component.scss"],
})
export class RangeSliderComponent implements OnInit {
  @Input() rangeStart: number;
  @Input() rangeEnd: number;
  @Input() initMinValue: number;
  @Input() initMaxValue: number;
  @Output() valueChange = new EventEmitter<{ minValue: number; maxValue: number }>();

  public isShow = true;
  public showStartPopup = true;
  public showEndPopup = true;
  public result: number;

  public minValue: number;
  public maxValue: number;

  public minMeridiem: string;
  public maxMeridiem: string;
  public rangeStartMeridiem: string;
  public rangeEndMeridiem: string;

  public rangeStartDisplay: number;
  public rangeEndDisplay: number;
  public minDisplayValue: number;
  public maxDisplayValue: number;

  ngOnInit(): void {
    this.minValue = this.initMinValue;
    this.maxValue = this.initMaxValue;
    this.minDisplayValue = this.formatDisplayValue(this.minValue);
    this.maxDisplayValue = this.formatDisplayValue(this.maxValue);

    this.rangeStartDisplay = this.formatDisplayValue(this.rangeStart);
    this.rangeEndDisplay = this.formatDisplayValue(this.rangeEnd);
    this.rangeStartMeridiem = this.calcMeridiem(this.rangeStart);
    this.rangeEndMeridiem = this.calcMeridiem(this.rangeEnd);

    this.minMeridiem = this.calcMeridiem(this.minValue);
    this.maxMeridiem = this.calcMeridiem(this.maxValue);

    this.calcResult();
    this.updateSliderUI();
  }

  toggleShowState() {
    this.isShow = !this.isShow;
  }

  toggleShowPopups(handlerValue: number, startEndValue: number) {
    return Math.abs(handlerValue - startEndValue) < 3 ? false : true;
  }

  onLeftInput(event: Event): void {
    const target = event.target as HTMLInputElement;

    this.minValue = Math.min(parseInt(target.value, 10), this.maxValue - 1);
    this.minDisplayValue = this.formatDisplayValue(this.minValue);
    this.minMeridiem = this.calcMeridiem(this.minValue);

    this.updateSliderUI();
    this.calcResult();
    this.showStartPopup = this.toggleShowPopups(this.minValue, this.rangeStart);
  }

  onRightInput(event: Event): void {
    const target = event.target as HTMLInputElement;

    this.maxValue = Math.max(parseInt(target.value, 10), this.minValue + 1);
    this.maxDisplayValue = this.formatDisplayValue(this.maxValue);
    this.maxMeridiem = this.calcMeridiem(this.maxValue);

    this.updateSliderUI();
    this.calcResult();
    this.showEndPopup = this.toggleShowPopups(this.maxValue, this.rangeEnd);
  }

  calcResult() {
    this.result = this.maxValue - this.minValue;
  }

  calcMeridiem(value: number) {
    if (value < 24) {
      if (value > 11) {
        return "PM";
      } else {
        return "AM";
      }
    } else {
      const valueOf24 = value % 24;
      if (valueOf24 > 11) {
        return "PM";
      } else {
        return "AM";
      }
    }
  }

  onUpdateTime() {
    this.emitValueChange();
    this.isShow = false;
  }

  private updateSliderUI(): void {
    const range = this.rangeEnd - this.rangeStart;
    const leftPercentage = ((this.minValue - this.rangeStart) / range) * 100;
    const rightPercentage = ((this.maxValue - this.rangeStart) / range) * 100;

    document.querySelector("#RangeSlider .range-slider-val-left").setAttribute("style", `width: ${leftPercentage}%`);
    document
      .querySelector("#RangeSlider .range-slider-val-right")
      .setAttribute("style", `width: ${100 - rightPercentage}%`);
    document
      .querySelector("#RangeSlider .range-slider-val-range")
      .setAttribute("style", `left: ${leftPercentage}%; right: ${100 - rightPercentage}%`);
    document.querySelector("#RangeSlider .range-slider-handle-left").setAttribute("style", `left: ${leftPercentage}%`);
    document
      .querySelector("#RangeSlider .range-slider-handle-right")
      .setAttribute("style", `left: ${rightPercentage}%`);
  }

  private formatDisplayValue(value: number) {
    return value % 12 === 0 ? 12 : value % 12;
  }

  private calcDateFormat(minValue: number, maxValue: number) {
    console.log(minValue);
    console.log(maxValue);

    return {};
  }

  private emitValueChange(): void {
    const {} = this.calcDateFormat(this.minValue, this.maxValue);

    this.valueChange.emit({ minValue: this.minValue, maxValue: this.maxValue });
  }
}
