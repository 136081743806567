export const tripStatusList = {
  ALL: {
    name: "ALL",
    color: "",
    description: "",
    displayName: "",
  },
  REQUESTED: {
    name: "REQUESTED",
    color: "yellow",
    description: "",
    displayName: "Requested",
  },
  REQUESTED_FOR_FUTURE: {
    name: "REQUESTED_FOR_FUTURE",
    color: "yellow",
    description: "",
    displayName: "Requested For Future",
  },
  AUTHORIZATION_REQUIRED: {
    name: "AUTHORIZATION_REQUIRED",
    color: "red",
    description: "",
    displayName: "Authorization Required",
  },
  CONFIRMED: {
    name: "CONFIRMED",
    color: "blue",
    description: "",
    displayName: "Confirmed",
  },
  REJECTED: {
    name: "REJECTED",
    color: "white",
    description: "",
    displayName: "Rejected",
  },
  ADMIN_REJECTED: {
    name: "ADMIN_REJECTED",
    color: "white",
    description: "",
    displayName: "Admin Rejected",
  },
  HEADING_TO_PICK_UP: {
    name: "HEADING_TO_PICK_UP",
    color: "blue",
    description: "",
    displayName: "Heading To Pick Up",
  },
  AT_PICK_UP: {
    name: "AT_PICK_UP",
    color: "blue",
    description: "",
    displayName: "At Pick Up",
  },
  ON_TRIP: {
    name: "ON_TRIP",
    color: "blue",
    description: "",
    displayName: "On Trip",
  },
  AT_STOP: {
    name: "AT_STOP",
    color: "blue",
    description: "",
    displayName: "At Stop",
  },
  COMPLETED: {
    name: "COMPLETED",
    color: "green",
    description: "",
    displayName: "Completed",
  },
  CUSTOMER_CANCELLED: {
    name: "CUSTOMER_CANCELLED",
    color: "red",
    description: "",
    displayName: "Customer Cancelled",
  },
  REQUEST_CANCELLED: {
    name: "REQUEST_CANCELLED",
    color: "red",
    description: "",
    displayName: "Request Cancelled",
  },
  CHAUFFEUR_CANCELLED: {
    name: "CHAUFFEUR_CANCELLED",
    color: "red",
    description: "",
    displayName: "Chauffeur Cancelled",
  },
};

export const tripPaymentStatusList = {
  ALL: {
    name: "ALL",
    color: "",
    description: "",
    displayName: "",
  },
  PAID: {
    name: "PAID",
    color: "green",
    description: "",
    displayName: "PAID",
  },
  NOT_INITIATED: {
    name: "NOT_INITIATED",
    color: "blue",
    description: "",
    displayName: "NOT INITIATED",
  },
  INCOMPLETE: {
    name: "INCOMPLETE",
    color: "red",
    description: "",
    displayName: "INCOMPLETE",
  },
  REFUND: {
    name: "REFUND",
    color: "blue",
    description: "",
    displayName: "REFUND",
  },
  DEFAULTED: {
    name: "DEFAULTED",
    color: "red",
    description: "",
    displayName: "DEFAULTED",
  },
};

export const authorizationStatusList = {
  ALL: {
    name: "ALL",
    color: "",
    description: "",
    displayName: "",
  },
  NOT_INITIATED: {
    name: "NOT_INITIATED",
    color: "blue",
    description: "",
    displayName: "NOT INITIATED",
  },
  AUTHORIZED: {
    name: "AUTHORIZED",
    color: "green",
    description: "",
    displayName: "AUTHORIZED",
  },
  AUTHORIZATION_FAILURE: {
    name: "AUTHORIZATION_FAILURE",
    color: "red",
    description: "",
    displayName: "AUTHORIZATION FAILURE",
  },
};

export const assignmentStatusList = {
  ALL: {
    name: "ALL",
    color: "",
    description: "",
    displayName: "",
  },
  PENDING_ASSIGNMENT: {
    name: "PENDING_ASSIGNMENT",
    color: "yellow",
    description: "",
    displayName: "PENDING ASSIGNMENT",
  },
  CHAUFFEUR_ASSIGNED: {
    name: "CHAUFFEUR_ASSIGNED",
    color: "green",
    description: "",
    displayName: "CHAUFFEUR_ASSIGNED",
  },
  CHAUFFEUR_CONFLICT: {
    name: "CHAUFFEUR_CONFLICT",
    color: "red",
    description: "",
    displayName: "CHAUFFEUR CONFLICT",
  },
  NO_ACTION_REQUIRED: {
    name: "NO_ACTION_REQUIRED",
    color: "red",
    description: "",
    displayName: "NO ACTION REQUIRED",
  },
};

export const tripStatusLogList = {
  REQUESTED: {
    name: "REQUESTED",
    color: "grey",
    description: "",
    displayName: "REQUESTED",
    primaryMessage: "Booking Request Created",
    secondaryMessage: "Pick up location address",
  },
  REQUESTED_FOR_FUTURE: {
    name: "REQUESTED_FOR_FUTURE",
    color: "grey",
    description: "",
    displayName: "REQUESTED",
    primaryMessage: "Scheduled Booking Request Created",
    secondaryMessage: "Pick up location address",
  },
  CONFIRMED: {
    name: "CONFIRMED",
    color: "grey",
    description: "",
    displayName: "CONFIRMED",
    primaryMessage: "Booking Request Confirmed",
    secondaryMessage: "Scheduled request was confirmed",
  },
  HEADING_TO_PICK_UP: {
    name: "HEADING_TO_PICK_UP",
    color: "grey",
    description: "",
    displayName: "HEADING TO PICK_UP",
    primaryMessage: "Request Accepted",
    secondaryMessage: "Chauffeur current location address",
  },
  CHAUFFEUR_ASSIGNED: {
    name: "CHAUFFEUR_ASSIGNED",
    color: "grey",
    description: "",
    displayName: "CHAUFFEUR ASSIGNED",
    primaryMessage: "Chauffeur Assigned for Booking",
    secondaryMessage: "Chauffeur Assigned for Booking",
  },
  CHAUFFEUR_UNASSIGNED: {
    name: "CHAUFFEUR_UNASSIGNED",
    color: "grey",
    description: "",
    displayName: "CHAUFFEUR UNASSIGNED",
    primaryMessage: "Chauffeur Unassigned for Booking",
    secondaryMessage: "Chauffeur Unassigned for Booking",
  },
  AUTHORIZATION_FAILED: {
    name: "AUTHORIZATION_FAILED",
    color: "grey",
    description: "",
    displayName: "AUTHORIZATION FAILED",
    primaryMessage: "Payment Authorization Failed",
    secondaryMessage: "Payment authorization for scheduled booking was failed",
  },
  AUTHORIZATION_CONFIRMED: {
    name: "AUTHORIZATION_CONFIRMED",
    color: "grey",
    description: "",
    displayName: "AUTHORIZATION CONFIRMED",
    primaryMessage: "Payment Authorization Success",
    secondaryMessage: "Payment was successfully authorized for booking",
  },
  REJECTED: {
    name: "REJECTED",
    color: "grey",
    description: "",
    displayName: "REJECTED",
    primaryMessage: "Trip Rejected",
    secondaryMessage: "Booking Request Rejected",
  },
  ADMIN_REJECTED: {
    name: "ADMIN_REJECTED",
    color: "grey",
    description: "",
    displayName: "ADMIN REJECTED",
    primaryMessage: "Trip Rejected",
    secondaryMessage: "Booking Request Rejected By Admin",
  },
  AT_PICK_UP: {
    name: "AT_PICK_UP",
    color: "grey",
    description: "",
    displayName: "AT PICK UP",
    primaryMessage: "Arrived for Pick up",
    secondaryMessage: "Chauffeur location address after arriving at pick up",
  },
  ON_TRIP: {
    name: "ON_TRIP",
    color: "grey",
    description: "",
    displayName: "ON TRIP",
    primaryMessage: "Trip Started",
    secondaryMessage: "Chauffeur location address upon starting trip",
  },
  AT_STOP: {
    name: "AT_STOP",
    color: "grey",
    description: "",
    displayName: "AT STOP",
    primaryMessage: "Reached Stop",
    secondaryMessage: "Chauffeur location address upon reaching stop",
  },
  COMPLETED: {
    name: "COMPLETED",
    color: "grey",
    description: "",
    displayName: "COMPLETED",
    primaryMessage: "Trip Completed",
    secondaryMessage: "Drop off location address",
  },
  COMPLETED_BY_ADMIN: {
    name: "COMPLETED_BY_ADMIN",
    color: "grey",
    description: "",
    displayName: "COMPLETED BY ADMIN",
    primaryMessage: "Trip Completed By Admin",
    secondaryMessage: "Booking Request Completed By Admin",
  },
  CUSTOMER_CANCELLED: {
    name: "CUSTOMER_CANCELLED",
    color: "grey",
    description: "",
    displayName: "CUSTOMER CANCELLED",
    primaryMessage: "Booking Cancelled by Customer",
    secondaryMessage: "Customer location upon cancellation",
  },
  REQUEST_CANCELLED: {
    name: "REQUEST_CANCELLED",
    color: "grey",
    description: "",
    displayName: "REQUEST CANCELLED",
    primaryMessage: "Booking Cancelled by Customer Before Assignment",
    secondaryMessage: "Customer location upon cancellation",
  },
  CHAUFFEUR_CANCELLED: {
    name: "CHAUFFEUR_CANCELLED",
    color: "grey",
    description: "",
    displayName: "CHAUFFEUR CANCELLED",
    primaryMessage: "Booking Cancelled by Chauffeur",
    secondaryMessage: "Chauffeur location upon cancellation",
  },
  CUSTOMER_DESTINATION_UPDATE: {
    name: "CUSTOMER_DESTINATION_UPDATE",
    color: "grey",
    description: "",
    displayName: "CUSTOMER DESTINATION UPDATE",
    primaryMessage: "Booking Request Updated",
    secondaryMessage: "Customer destination address location changed",
  },
  CHAUFFEUR_REJECTED: {
    name: "CHAUFFEUR_REJECTED",
    color: "grey",
    description: "",
    displayName: "CHAUFFEUR REJECTED",
    primaryMessage: "Chauffeur Trip Rejected",
    secondaryMessage: "Booking Request Rejected By Chauffeur",
  },
  CHAUFFER_REJECTED_TIMEOUT: {
    name: "CHAUFFER_REJECTED_TIMEOUT",
    color: "grey",
    description: "",
    displayName: "CHAUFFER REJECTED TIMEOUT",
    primaryMessage: "Chauffeur Trip Rejected Timeout",
    secondaryMessage: "Booking Request Rejected Timeout By Chauffeur",
  },
  CHAUFFEUR_REQUESTED: {
    name: "CHAUFFEUR_REQUESTED",
    color: "grey",
    description: "",
    displayName: "CHAUFFEUR REQUESTED",
    primaryMessage: "Chauffeur Requested",
    secondaryMessage: "Chauffeur Requested location address",
  },
  TRIP_NOTIFY_MANUALLY_ENABLED: {
    name: "TRIP_NOTIFY_MANUALLY_ENABLED",
    color: "grey",
    description: "",
    displayName: "TRIP NOTIFY MANUALLY ENABLED",
    primaryMessage: "Trip notify manually enabled",
    secondaryMessage: "Trip notify manually enabled",
  },
  TRIP_DATE_TIME_CHANGED_MANUALLY: {
    name: "TRIP_DATE_TIME_CHANGED_MANUALLY",
    color: "grey",
    description: "",
    displayName: "TRIP DATE TIME CHANGED MANUALLY",
    primaryMessage: "Trip date time changed manually",
    secondaryMessage: "Trip date time changed manually",
  },
  TRIP_CAR_CATEGORY_CHANGED_MANUALLY: {
    name: "TRIP_CAR_CATEGORY_CHANGED_MANUALLY",
    color: "grey",
    description: "",
    displayName: "TRIP CAR CATEGORY CHANGED MANUALLY",
    primaryMessage: "Trip car category changed manually",
    secondaryMessage: "Trip car category changed manually",
  },
  SYSTEM_CHAUFFEUR_CANCELLED: {
    name: "SYSTEM_CHAUFFEUR_CANCELLED",
    color: "grey",
    description: "",
    displayName: "SYSTEM CHAUFFEUR CANCELLED",
    primaryMessage: "Trip Cancelled",
    secondaryMessage: "Cancelled by system since trip was not initiated",
  },
  TRIP_OTP_SHARED_BY_CUSTOMER: {
    name: "TRIP_OTP_SHARED_BY_CUSTOMER",
    color: "grey",
    description: "",
    displayName: "TRIP OTP SHARED BY CUSTOMER",
    primaryMessage: "Otp Shared By Customer",
    secondaryMessage: "Customer Pressed Share Otp Button",
  },
  TRIP_OTP_UNSHARED_BY_CUSTOMER: {
    name: "AT_PICK_UP",
    color: "grey",
    description: "",
    displayName: "TRIP OTP UNSHARED BY CUSTOMER",
    primaryMessage: "Otp Un-Shared By Customer",
    secondaryMessage: "Customer Pressed Un-Share Otp Button",
  },
  TRIP_NOTIFY_MANUALLY_ENABLED_CUSTOMER: {
    name: "TRIP_NOTIFY_MANUALLY_ENABLED_CUSTOMER",
    color: "grey",
    description: "",
    displayName: "TRIP NOTIFY MANUALLY ENABLED CUSTOMER",
    primaryMessage: "Trip notify manually enabled by customer",
    secondaryMessage: "Trip notify manually enabled by customer",
  },
};

export const tripSubTypeStatusList = {
  SCHEDULED: {
    name: "SCHEDULED",
    color: "green",
    description: "",
    displayName: "SCHEDULED",
  },
  ONDEMAND: {
    name: "ONDEMAND",
    color: "blue",
    description: "",
    displayName: "ON-DEMAND",
  }
};

export const tripTypeStatus = {
  ALL: {
    name: "ALL",
    color: "",
    description: "",
    displayName: "",
  },
  RIDE_NOW: {
    name: "RIDE_NOW",
    color: "green",
    description: "",
    displayName: "RIDE NOW",
  },
  SCHEDULED: {
    name: "SCHEDULED",
    color: "yellow",
    description: "",
    displayName: "SCHEDULED",
  },
  HOURLY: {
    name: "HOURLY",
    color: "blue",
    description: "",
    displayName: "HOURLY",
  }
};
