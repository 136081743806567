import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { APP_BASE_HREF } from '@angular/common';
import { ActivateComponent } from './dashboard/shared-components/activate/activate.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CustomersEditModalComponent } from './dashboard/customers/customers-edit-modal/customers-edit-modal.component';
import { DashboardRoutingModule } from './dashboard/dashboard-routing.module';
import { ExcelService } from 'src/app/_services/excel.service';
import { HeaderComponent } from './dashboard/shared-components/header/header.component';
import { HttpConfigInterceptor } from '../app/util/interceptor/httpconfig.interceptor';
import { LoaderService } from './_services/loader.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NavLeftComponent } from './dashboard/shared-components/nav-left/nav-left.component';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChatModule } from '@pazznetwork/ngx-chat';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TitleCasePipe } from 'src/app/_pipes/titleCase.pipe';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { keys } from './_config/api-keys';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { TenantServices } from './_services/tenant.service';
import { SettingsModule } from './dashboard/settings/settings.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './app/_config/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, NavLeftComponent, HeaderComponent, ActivateComponent, TitleCasePipe],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AuthRoutingModule,
    DashboardRoutingModule,
    FormsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    HttpClientModule,
    NgxIntlTelInputModule,
    NgxSkeletonLoaderModule,
    NgSelectModule,
    UiSwitchModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxChatModule.forRoot(),
    RatingModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: keys.agmApiKey,
    }),
    AgmDirectionModule,
    SettingsModule
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    ExcelService,
    LoaderService,
    TenantServices,
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [
    CustomersEditModalComponent
  ]
})
export class AppModule { }
