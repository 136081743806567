import { Component, OnInit, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import {
  NgbModal,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-discard-changes',
  templateUrl: './discard-changes.component.html',
  styleUrls: ['./discard-changes.component.scss']
})
export class DiscardChangesComponent implements OnInit {

  public modalRef: BsModalRef;
  @Input() fromParent;

  constructor(
    private modal: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    
  }
  hide() {
    this.modal.dismissAll({isConfirm: false});
  }

  editBack() {
    this.activeModal.close({isConfirm: true}); // Pass data back to the parent component
  }

}
