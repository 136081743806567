// multi-select.component.ts
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
})
export class CounterComponent implements OnInit {
  // Outputs to emit the changes to the parent
  // @Output() countChange = new EventEmitter<number>();
  // @Output() checkboxChange = new EventEmitter<boolean>();

  @Input() label: string = "";
  @Input() inCount?: number;
  @Input() key: string = "";
  @Input() min: number = 0;
  @Input() max: number = Infinity;
  @Input() parentStateHandler?: (key: string, { count, check }: { count?: number; check?: boolean }) => void;
  @Input() withCount?: boolean = false;
  @Input() price?: number;
  @Input() readOnly?: boolean = false;

  constructor() {}

  public countState: number = 1; // Checkbox counter state

  ngOnInit(): void {
    this.countState = this.inCount ?? 0;
  }

  // public increment() {
  //   if (this.readOnly || this.countState >= this.max) return;
  //   this.countState = this.countState + 1;
  //   // this.countChange.emit(this.count); // Emit updated count to the parent
  //   this.parentStateHandler(this.key, {
  //     count: this.countState,
  //   });
  // }

  // public decrement() {
  //   if (this.countState <= this.min || this.readOnly) return;
  //   this.countState = this.countState - 1;
  //   // this.countChange.emit(this.count); // Emit updated count to the parent
  //   this.parentStateHandler(this.key, {
  //     count: this.countState,
  //   });
  // }
  increment() {
    if (this.readOnly) return;

    if (this.countState === 0) {
      this.countState = this.min;
    } else if (this.countState < this.max) {
      this.countState += 1;
    }

    this.parentStateHandler(this.key, { count: this.countState });
  }

  decrement() {
    if (this.readOnly) return;

    if (this.countState <= this.min) {
      this.countState = 0;
    } else {
      this.countState -= 1;
    }

    this.parentStateHandler(this.key, { count: this.countState });
  }

  public onCheckboxChange(event: any) {
    // this.checkboxChange.emit(this.isChecked); // Emit checkbox status to the parent
    this.parentStateHandler(this.key, {
      count: this.countState,
    });
  }
}
