import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { AuthServices } from "./auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "../_config/url-endpoint";

@Injectable({
  providedIn: "root",
})
export class AnalyticsServices {
  private storageKey = "myDataCustomer";
  private dataSource = new BehaviorSubject<string>(localStorage.getItem(this.storageKey) || "initial value");
  public data = this.dataSource.asObservable();

  private customerRegistrations = new BehaviorSubject(0);
  public currentCustomerRegistrationsData = this.customerRegistrations.asObservable();

  public performanceData = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private authService: AuthServices) {
    this.data.subscribe((value) => {
      localStorage.setItem(this.storageKey, value);
    });
  }

  setCustomerRegistrationsData(data) {
    this.customerRegistrations.next(data);
  }

  getCustomer(queryString): Observable<any> {
    let url = `${endpoints.analyticsPage.getCustomerUrl}?${queryString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  getCustomerCount(): Observable<any> {
    return this.http.get(endpoints.analyticsPage.getCustomerCountUrl);
  }

  getSubscriptionsCount(tenantId: string): Observable<any> {
    return this.http.get(`${endpoints.personalAssistant.subscriptionsUrl.getSubscriptionsCount}?tenantId=${tenantId}`);
    // return this.http.get(endpoints.personalAssistant.subscriptionsUrl.getSubscriptionsCount);
  }

  downloadFile(queryString): Observable<any> {
    const url = `${endpoints.analyticsPage.getCustomerDownloadUrl}?${queryString}`;

    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(url, { headers, responseType: "blob" });
  }

  getAdministrativeLogs(userId): Observable<any> {
    let url = `${endpoints.analyticsPage.getAdministrativeLogsUrl}/${userId}`;
    return this.http.get(url);
  }

  // getCustomerDetails(userId) : Observable<any>{
  //   let url = `${endpoints.analyticsPage.getCustomerDetailsUrl}/${userId}`;
  //   return this.http.get(url);
  // }
  getPaymentCustomer(userId): Observable<any> {
    let url = `${endpoints.analyticsPage.getPaymentCustomerUrl}/${userId}?status=ACTIVE`;
    return this.http.get(url);
  }

  getVerificationCodeCustomer(userId): Observable<any> {
    let url = `${endpoints.analyticsPage.getPaymentCustomerUrl}/${userId}?status=ACTIVE`;
    return this.http.get(url);
  }

  getData(row: any = []) {
    this.dataSource.next(row);
    localStorage.setItem(this.storageKey, JSON.stringify(row));
  }

  changeStatus(customerId, body): Observable<any> {
    return this.http.post(endpoints.customerPage.changeStatus + customerId, body);
  }

  getDashboardKeyInsights(fromDate, toDate, tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardKeyInsights}?fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }

  getDashboardRevenue(fromDate, toDate, tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardRevenue}?fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }

  getDashboardTotalRevenue(fromDate, toDate, tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardTotalRevenue}?fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }

  getDashboardBookings(fromDate, toDate, tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardBookings}?fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }

  getDashboardCustomerRegistrations(fromDate, toDate): Observable<any> {
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardCustomerRegistrations}?fromDate=${fromDate}&toDate=${toDate}`,
    );
  }

  getDashboardKeyPerformance(fromDate, toDate, customerId = null, tenantId = null): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    if (customerId) {
      return this.http.get(
        `${endpoints.dashboardPage.getDashboardKeyPerformance}?fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}${tenantUrlString}`,
      );
    }
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardKeyPerformance}?fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }

  getDashboardTopCustomers(sortColumn, sortBy, fromDate, toDate, tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardTopCustomers}?sortBy=${sortColumn}&order=${sortBy}&fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }

  getDashboardTopChauffeurs(sortColumn, sortBy, fromDate, toDate, tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    return this.http.get(
      `${endpoints.dashboardPage.getDashboardTopChauffeurs}?sortBy=${sortColumn}&order=${sortBy}&fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }

  getCustomerDetails(userId) {
    let url = `${endpoints.auth.meUrl}/${userId}`;
    return this.http.get(url);
  }

  getDeviceDetails(userId): Observable<any> {
    let url = `${endpoints.customerPage.deviceDetails}/${userId}`;
    return this.http.get(url);
  }

  getCustomerOtpDetails(userId) {
    let url = `${endpoints.customerPage.otpDetails}/${userId}/CUSTOMER_APP/MOBILE_OTP`;
    return this.http.get(url);
  }

  getQuotation(customerId, tenantId = null): Observable<any> {
    let queryParams = "";
    if (tenantId) {
      queryParams = `?tenantId=${tenantId}`;
    }
    let url = `${endpoints.customerPage.getCustomerUrl}/${customerId}${queryParams}`;
    return this.http.get(url);
  }

  getCustomerTotalSpend(customerId, tenantId = null): Observable<any> {
    let queryParams = "";
    if (tenantId) {
      queryParams = `?tenantId=${tenantId}`;
    }
    let url = `${endpoints.customerPage.getCustomerTotalSpendUrl}/${customerId}${queryParams}`;
    return this.http.get(url);
  }

  public getPerformanceData(): Observable<any> {
    return this.performanceData.asObservable();
  }

  public setPerformanceData(data: any) {
    this.performanceData.next({ result: data });
  }

  public getCarTypePerformanceData(customerId, tenantId = null): Observable<any> {
    let queryParams = "";
    if (tenantId) {
      queryParams = `?tenantId=${tenantId}`;
    }
    return this.http.get(`${endpoints.customerPage.getCarTypePerformanceUrl}/${customerId}${queryParams}`);
  }

  getTripFineKeyInsights(fromDate, toDate, tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `&tenantId=${tenantId}`;
    }
    return this.http.get(
      `${endpoints.dashboardPage.getTripFineUrl}?fromDate=${fromDate}&toDate=${toDate}${tenantUrlString}`,
    );
  }
}
