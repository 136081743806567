import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SettingsComponent } from "./settings.component";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "../shared.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ServiceAccessTableComponent } from "./service-access/service-access-table/service-access-table.component";
import { ServiceAccessModalComponent } from "./service-access/service-access-modal/service-access-modal.component";
import { ServiceAccessCountryModalComponent } from "./service-access-country/service-access-country-modal/service-access-country-modal.component";
import { ServiceAccessCountryTableComponent } from "./service-access-country/service-access-country-table/service-access-country-table.component";

const routes: Routes = [
  {
    path: "",
    component: SettingsComponent,
  },
];

@NgModule({
  declarations: [
    SettingsComponent,
    ServiceAccessTableComponent,
    ServiceAccessModalComponent,
    ServiceAccessCountryTableComponent,
    ServiceAccessCountryModalComponent,
  ],
  imports: [CommonModule, SharedModule, NgxSkeletonLoaderModule, RouterModule.forChild(routes)],
})
export class SettingsModule {}
