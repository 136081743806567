import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { endpoints } from "../_config/url-endpoint";

/**
 *
 *
 * @export
 * @class DriverServices
 */
@Injectable({
  providedIn: "root",
})
export class DriverServices {
  private saveDriverVal = new Subject<any>();
  private storageKey = "myDataDriver";
  private dataSource = new BehaviorSubject<string>(
    localStorage.getItem(this.storageKey) || "initial value"
  );
  public data = this.dataSource.asObservable();

  /**
   * Creates an instance of DriverServices.
   *
   * @param {HttpClient} http
   * @memberof DriverServices
   */
  constructor(private http: HttpClient) {
    this.data.subscribe((value) => {
      localStorage.setItem(this.storageKey, value);
    });
  }

  /**
   * Get all drivers
   *
   * @param {*} queryString
   * @returns {Observable<any>}
   * @memberof DriverServices
   */
  getChauffer(queryString): Observable<any> {
    let url = `${endpoints.driverPage.getChaufferUrl}?${queryString}`;
    return this.http.get(url);
  }

  getChaufferCount(tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `?tenantId=${tenantId}`;
    }
    const url = `${endpoints.driverPage.getChaufferCountUrl}${tenantUrlString}`;
    return this.http.get(url);
  }

  downloadFile(queryString): Observable<any> {
    const url = `${endpoints.driverPage.getChaufferDownloadUrl}?${queryString}`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(url, { headers, responseType: "blob" });
  }

  createDriver(body): Observable<any> {
    return this.http.post(endpoints.driverPage.driverUrl, body);
  }

  getDriver(driverId): Observable<any> {
    let url = `${endpoints.driverPage.driverUrl}/${driverId}`;
    return this.http.get(url);
  }

  updateDriver(driverId, body): Observable<any> {
    let url = `${endpoints.driverPage.driverUrl}/${driverId}`;
    return this.http.put(url, body);
  }

  assignCar(body): Observable<any> {
    return this.http.post(endpoints.driverPage.assignCarUrl, body);
  }

  changeStatus(driverId, body): Observable<any> {
    let url = `${endpoints.driverPage.changeStatusUrl}/${driverId}`;
    return this.http.post(url, body);
  }

  changeState(driverId, body): Observable<any> {
    let url = `${endpoints.driverPage.changeStateByAdmin}/${driverId}`;
    return this.http.post(url, body);
  }

  performanceDriver(driverId, body): Observable<any> {
    let url = `${endpoints.driverPage.performanceUrl}/${driverId}`;
    return this.http.post(url, body);
  }

  workingDriver(driverId, body): Observable<any> {
    let url = `${endpoints.driverPage.workingUrl}/${driverId}`;
    return this.http.post(url, body);
  }

  activeChauffeurs(queryString): Observable<any> {
    let query = "";
    if (queryString) {
      query = `?${queryString}`;
    }
    let url = `${endpoints.driverPage.activeChauffer}${query}`;
    return this.http.get(url);
  }

  activityDriver(driverId, body): Observable<any> {
    let url = `${endpoints.driverPage.activityUrl}/${driverId}`;
    return this.http.post(url, body);
  }

  earningsDriver(driverId, body): Observable<any> {
    let url = `${endpoints.driverPage.earningsUrl}/${driverId}`;
    return this.http.post(url, body);
  }

  selectedValue$ = this.dataSource.asObservable();

  getData(row: any = []) {
    this.dataSource.next(row);
    localStorage.setItem(this.storageKey, JSON.stringify(row));
  }

  public setRefreshDriver(data: any) {
    this.saveDriverVal.next({ result: data });
  }

  public getRefreshDriver(): Observable<any> {
    return this.saveDriverVal.asObservable();
  }

  getAcceptanceRate(body): Observable<any> {
    return this.http.post(endpoints.driverPage.acceptanceRateUrl, body);
  }

  assignChauffeur(body): Observable<any> {
    let url = `${endpoints.allBookings.assignChauffeurUrl}`;
    return this.http.post(url, body);
  }

  reject(body): Observable<any> {
    let url = `${endpoints.allBookings.rejectUrl}`;
    return this.http.post(url, body);
  }

  /**
   * Enable notify
   *
   * @param {string} tripId
   * @returns {Observable<any>}
   * @memberof DriverServices
   */
  enableNotify(tripId: string): Observable<any> {
    let url = `${endpoints.allBookings.enableNotifyUrl}/${tripId}`;
    return this.http.post(url, {});
  }

  validateChauffeur(tripId, chauffeurId): Observable<any> {
    let url = `${endpoints.allBookings.validateChauffeurUrl}/${tripId}/${chauffeurId}`;
    return this.http.get(url);
  }

  /**
   * Api request to get the trip hot zones
   *
   * @param {*} tenantId
   * @returns {Observable<any>}
   * @memberof DriverServices
   */
  fetchHotZonesForChauffeurs(tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `?tenantId=${tenantId}`;
    }
    const url = `${endpoints.fleet.hotZones}${tenantUrlString}`;
    return this.http.get(url);
  }

  /**
   * Api request to get the trip hot zones
   *
   * @param {*} tenantId
   * @returns {Observable<any>}
   * @memberof DriverServices
   */
  fetchCategoryFleetCount(tenantId: string = null): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `?tenantId=${tenantId}`;
    }
    const url = `${endpoints.fleet.categoryCount}${tenantUrlString}`;
    return this.http.get(url);
  }

  /**
   * Api request to get the fleet count per tenant (optional)
   *
   * @param {string} [tenantId=null]
   * @returns {Observable<any>}
   * @memberof DriverServices
   */
  fleetCount(tenantId: string = null): Observable<any> {
    let url = `${endpoints.driverPage.fleetCount}`;
    if (tenantId) {
      url = `${url}?tenantId=${tenantId}`;
    }
    return this.http.get(url);
  }

  getChauffeurOtpDetails(userId) {
    let url = `${endpoints.driverPage.otpDetails}/${userId}/DRIVER_APP/MOBILE_OTP`;
    return this.http.get(url);
}
}
